"use client";

import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { updateUserTimezone } from "./user-timezone-action";

export function UserTimezone() {
  const { data: session } = useSession();

  const handleTimezoneUpdate = (value: number) => {
    updateUserTimezone(value).catch(() => {});
  };

  useEffect(() => {
    if (!session) {
      return;
    }

    if (session.user.timezoneOffset !== undefined) {
      return;
    }

    const offset = new Date().getTimezoneOffset();
    handleTimezoneUpdate(offset);
  }, [session]);

  return null;
}
